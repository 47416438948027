import * as React from 'react';

const Ribbon: React.FC<{
	align?: 'left' | 'right';
	color?: string;
	children?: React.ReactNode;
}> = ({ align, color, children }) => {
	return (
		<div
			style={{
				position: 'fixed',
				background: color ?? '#08769b',
				boxShadow: `0 0 0 999px ${color ?? '#08769b'}`,
				clipPath: 'inset(0 -100%)',
				color: 'white',
				inset: align === 'right' ? '0 0 auto auto' : '0 auto auto 0',
				lineHeight: '2em',
				textTransform: 'capitalize',
				transformOrigin: align === 'right' ? '0 0' : '100% 0',
				transform:
					align === 'right'
						? 'translate(29.3%) rotate(45deg)'
						: 'translate(-29.3%) rotate(-45deg)',
			}}
		>
			{children ?? 'Etiqueta'}
		</div>
	);
};

export default Ribbon;
