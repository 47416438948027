import React, { useContext, useState } from 'react';

import {
	Segment,
	Button,
	Header,
	Modal,
	Icon,
	Image as SuiImage,
} from 'semantic-ui-react';

import { ScreenSizeContext } from '../../contexts/ScreenContext';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { uploadImage } from '../../store/images';

type ImageModalProps = {
	show: boolean;
	image?: string;
	size: number;
	aspect?: number;
	domain: string;
	entity: string;
	type: string;
	changeImage: (image: string) => void;
	closeImage: () => void;
};

const ImageModal: React.FC<ImageModalProps> = ({
	show,
	image,
	size,
	aspect,
	domain,
	entity,
	type,
	changeImage,
	closeImage,
}) => {
	const dispatch = useAppDispatch();

	const screenSize = useContext(ScreenSizeContext);

	const { loading } = useAppSelector((state) => ({
		loading: state.img.loading,
	}));

	const [localImage, setLocalImage] = useState<string>();
	const [changed, setChanged] = useState(false);

	const modalMount = () => {
		if (!image) {
			editImage();
		} else {
			setLocalImage(image);
		}
	};

	const editImage = () => {
		const input: HTMLInputElement = document.createElement('input');
		input.type = 'file';
		input.accept = 'image/*';
		input.click();
		input.onchange = (event) =>
			fileSelect((event?.target as HTMLInputElement)?.files?.[0]);
	};

	const fileSelect = (file?: File) => {
		if (!file) {
			alert('Escolha uma imagem!');
		} else if (file.type.split('/')[0] !== 'image') {
			alert('Conteúdo não é uma imagem!');
			// } else if (file.size > 1024 * 1024) {
			// 	alert('Imagem precisa ser menor que 1MB!');
		} else {
			fileWrite(file);
		}
	};

	const fileWrite = async (file?: Blob) => {
		if (file) {
			const imgUrl = URL.createObjectURL(file);
			const croped = await cropImage(imgUrl, aspect);
			const resized = await resizeImage(croped, size);

			setLocalImage(resized);
			setChanged(true);
		}
	};

	const cropImage = (url: string, aspectRatio?: number) => {
		// we return a Promise that gets resolved with our canvas element
		return new Promise<string>((resolve) => {
			// this image will hold our source image data
			const inputImage = new Image();

			// start loading our image
			inputImage.src = url;

			// we want to wait for our image to load
			inputImage.onload = () => {
				// let's store the width and height of our image
				const inputWidth = inputImage.naturalWidth;
				const inputHeight = inputImage.naturalHeight;

				// get the aspect ratio of the input image
				const inputImageAspectRatio = inputWidth / inputHeight;

				if (!aspectRatio) aspectRatio = inputImageAspectRatio;

				// if it's bigger than our target aspect ratio
				let outputWidth = inputWidth;
				let outputHeight = inputHeight;
				if (inputImageAspectRatio > aspectRatio) {
					outputWidth = inputHeight * aspectRatio;
				} else if (inputImageAspectRatio < aspectRatio) {
					outputHeight = inputWidth / aspectRatio;
				}

				// calculate the position to draw the image at
				const outputX = (outputWidth - inputWidth) * 0.5;
				const outputY = (outputHeight - inputHeight) * 0.5;

				// create a canvas that will present the output image
				const outputImage = document.createElement('canvas');

				// set it to the same size as the image
				outputImage.width = outputWidth;
				outputImage.height = outputHeight;

				// draw our image at position 0, 0 on the canvas
				const ctx = outputImage.getContext('2d');
				ctx?.drawImage(inputImage, outputX, outputY);

				resolve(outputImage.toDataURL());
			};
		});
	};

	const resizeImage = (url: string, size: number) => {
		// we return a Promise that gets resolved with our canvas element
		return new Promise<string>((resolve) => {
			// this image will hold our source image data
			const inputImage = new Image();

			// start loading our image
			inputImage.src = url;

			// we want to wait for our image to load
			inputImage.onload = () => {
				// let's store the width and height of our image
				const inputWidth = inputImage.naturalWidth;
				const inputHeight = inputImage.naturalHeight;

				// get the aspect ratio of the input image
				const inputImageAspectRatio = inputWidth / inputHeight;

				// create a canvas that will present the output image
				const outputImage = document.createElement('canvas');

				// Set width and height
				outputImage.width =
					inputImageAspectRatio < 1 ? size : size * inputImageAspectRatio;
				outputImage.height =
					inputImageAspectRatio < 1 ? size / inputImageAspectRatio : size;

				// draw our image at position 0, 0 on the canvas
				const ctx = outputImage.getContext('2d');

				ctx?.drawImage(inputImage, 0, 0, outputImage.width, outputImage.height);

				resolve(outputImage.toDataURL());
			};
		});
	};

	const handleUploadImage = async () => {
		if (localImage) {
			const image = await fetch(localImage).then((r) => r.blob());

			dispatch(
				uploadImage({
					image,
					domain,
					entity,
					type,
					updateFunction: handleChangeImage,
				})
			);
		}
	};

	const handleChangeImage = (image: string) => {
		changeImage(image);
		setLocalImage(undefined);
		closeImage();
	};

	const closeModal = () => {
		setLocalImage(undefined);
		closeImage();
	};

	return (
		<Modal
			open={show}
			basic={screenSize !== 'mobile'}
			closeIcon={true}
			closeOnEscape={true}
			closeOnDimmerClick={false}
			size="tiny"
			style={
				screenSize === 'mobile'
					? { height: 'calc(100vh - 2em)', margin: 0 }
					: {}
			}
			onClose={closeModal}
			onMount={modalMount}
		>
			<Header icon="picture" content="Edição da imagem" />
			<Modal.Content
				style={{
					...(screenSize === 'mobile'
						? { height: 'calc(100vh - 12em)', padding: 0 }
						: { minHeight: '30vh' }),
				}}
			>
				<Segment
					inverted={screenSize !== 'mobile'}
					textAlign="center"
					placeholder
					style={{
						alignItems: 'center',
						backgroundColor: 'transparent',
						height: '100%',
					}}
				>
					{localImage ? (
						<SuiImage centered src={localImage} />
					) : (
						<Header icon style={{ cursor: 'pointer' }}>
							<Icon name="file image" onClick={editImage} />
							Clique para escolher.
						</Header>
					)}
				</Segment>
			</Modal.Content>
			<Segment basic textAlign="center">
				<Button
					content="Cancelar"
					icon="cancel"
					color="red"
					inverted
					floated="left"
					onClick={closeModal}
				/>
				<Button
					basic
					icon="folder open"
					color="grey"
					inverted
					onClick={() => editImage()}
				/>
				<Button
					content="Enviar"
					icon="checkmark"
					color="green"
					inverted
					floated="right"
					loading={loading}
					disabled={!localImage || !changed || loading}
					onClick={handleUploadImage}
				/>
			</Segment>
		</Modal>
	);
};

export default ImageModal;
