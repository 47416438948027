import {
	PayloadAction,
	SerializedError,
	createAsyncThunk,
	createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { api } from '../../api';
import { authRefresh } from '../auth';
import { Challenge } from '../challenges';
import { Enrollment } from '../enrollments';
import { Event } from '../events';
import { Tournament } from '../events';
import { Draw, Group, knockoutRounds, orderByTeamStats } from '../groups';
import { Category, Chall, Ranking, ResultSetup, Setup } from '../leagues';
import { Match } from '../matches';
import { Gender } from '../people';

export type Load = {
	loading?: boolean;
};

export type Error = {
	error?: boolean;
};

export type Closed = {
	closed?: boolean; // Grupo fechado
};

export type Result = {
	chall?: string; // Id do desafio
	champ?: string; // Id do evento
	tourn?: string; // Id do torneio
	group?: string; // Id do grupo
	draw?: Draw;
	rank: string; // Id do ranking
	team: string; // Id do time
	position: number; // Posição no grupo
	points: number; // Pontuação obtida
	descr?: string;
	acquisition?: string;
	expiration?: string;
};

export type Points = {
	rank: string;
	team?: string;
	champ?: string;
	tourn?: string;
	group?: string;
	chall?: string;
	mail: string;
	name: string;
	descr?: string;
	acquisition: string;
	expiration: string;
	points: number;
	pos?: number;
	type: 'player' | 'team';
	enrollment?: Pick<Enrollment, 'title' | 'descr' | 'logo'>;
	event?: Pick<Event, 'name' | 'edition' | 'poster'>;
	tournament?: Pick<Tournament, 'name' | 'sign'>;
	draw?: Pick<Group, 'title' | 'draw' | 'fase'>;
	challenge?: Pick<Challenge, 'age' | 'cat'>;
	active: boolean;
};

export type Balance = {
	mail: string;
	name?: string;
	photo?: string;
	birth?: string;
	gender?: Gender;
	balance: number;
	position?: number;
	categories?: Category[];
	loading?: boolean;
	error?: SerializedError;
};

export type Filters = {
	genders?: Gender[];
	categories?: Category[];
	search?: string;
};

interface RankingState {
	rankings: Ranking[];
	ranking?: Ranking;
	results?: (Result & Closed & Load & Error)[];
	balances: Balance[];
	positions: Balance[];
	points: Points[];
	editPoints?: Points;
	send: boolean;
	filters: Filters;
	loading?: 'ranking' | 'balances' | 'points';
	error?: SerializedError;
}

const initialState: RankingState = {
	rankings: [],
	ranking: undefined,
	balances: [],
	positions: [],
	points: [],
	send: false,
	filters: {
		genders: [],
		categories: [],
		search: '',
	},
	loading: undefined,
	error: undefined,
};

export const rankingSlice = createSlice({
	name: 'rankings',
	initialState: initialState,
	reducers: {
		calcTournResults: (
			state,
			{
				payload,
			}: PayloadAction<{
				groups: Group[];
				matches: Match[];
				tournament: Tournament;
				event: Event;
			}>
		) => {
			state.results = calcGroupsResults(
				payload.groups,
				payload.matches,
				payload.tournament,
				payload.event
			).sort((a, b) => b.points - a.points);
		},
		calcRankResults: (
			state,
			{
				payload,
			}: PayloadAction<{
				challenge: Challenge;
				enrollments: Enrollment[];
				match: Match;
				ranking: Ranking;
			}>
		) => {
			state.results = calcChallResults(
				payload.challenge,
				payload.enrollments,
				payload.match,
				payload.ranking,
				state.balances
			).sort((a, b) => b.points - a.points);
		},
		setResultsAcquisition: (
			state,
			{ payload }: PayloadAction<{ acquisition: string; expiration: string }>
		) => {
			state.results = state.results?.map((result) => ({
				...result,
				acquisition: payload.acquisition,
				expiration: payload.expiration,
			}));
		},
		setResultExpiration: (
			state,
			{ payload }: PayloadAction<{ team: string; expiration: string }>
		) => {
			state.results = state.results?.map((result) =>
				result.team === payload.team
					? {
							...result,
							expiration: payload.expiration,
						}
					: result
			);
		},
		setFilters: (state, { payload }: PayloadAction<Filters>) => {
			state.filters = {
				...state.filters,
				...payload,
			};
		},
		filterBalances: (
			state,
			{ payload: { categories, genders, search } }: PayloadAction<Filters>
		) => {
			const filterCategories = (balances: Balance[]) =>
				balances.filter((balance) =>
					!categories || categories.length === 0
						? true
						: categories.some((f) =>
								balance.categories?.some(
									(b) =>
										b.id === f.id ||
										(b.name === f.name &&
											b.gender === f.gender &&
											b.sport === f.sport &&
											b.league === f.league)
								)
							)
				);
			const filterGender = (balances: Balance[]) =>
				balances.filter((balance) =>
					!genders || genders.length === 0
						? true
						: !balance.gender
							? false
							: genders.includes(balance.gender)
				);

			const filterSearch = (balances: Balance[]) =>
				balances.filter(
					(pos) =>
						!search ||
						pos.name?.toLowerCase()?.includes(search.toLowerCase() ?? '') ||
						pos.mail.includes(search ?? '')
				);

			state.positions = calcPositions(
				filterSearch(filterGender(filterCategories(state.balances)))
			);
		},
		editPoints: (state, { payload }: PayloadAction<Points>) => {
			state.editPoints = payload;
		},
		changePoints: (
			state,
			{
				payload,
			}: PayloadAction<{ field: keyof Points; value: string | number }>
		) => {
			state.editPoints = {
				...state.editPoints,
				[payload.field]: payload.value,
			} as Points;
		},
		cancelPoints: (state) => {
			state.editPoints = undefined;
			state.send = false;
		},
		setRankings: (state, { payload }: PayloadAction<Ranking[]>) => {
			state.rankings = payload ?? [];
		},
		setSend: (state, { payload }: PayloadAction<boolean>) => {
			state.send = payload;
		},
		resetFilters: (state) => {
			state.filters = initialState.filters;
		},
		resetResults: (state) => {
			state.results = undefined;
		},
		resetPoints: (state) => {
			state.points = [];
		},
		resetBalances: (state) => {
			state.balances = [];
			state.positions = [];
		},
		resetRanking: (state) => {
			state.ranking = undefined;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getRanking.fulfilled, (state, { payload }) => {
				state.ranking = payload;
				state.loading = undefined;
				state.error = undefined;
			})
			.addCase(getRanking.pending, (state) => {
				state.loading = 'ranking';
				state.error = undefined;
			})
			.addCase(getRanking.rejected, (state, { payload }) => {
				state.loading = undefined;
				state.error = payload;
			});
		builder
			.addCase(getBalances.fulfilled, (state, { payload, meta }) => {
				const updatedBalances = [
					...(!meta.arg.mail
						? []
						: state.balances.filter((b) => b.mail !== meta.arg.mail)),
					...payload,
				];

				state.balances = updatedBalances;
				state.positions = calcPositions(updatedBalances);
				state.loading = undefined;
			})
			.addCase(getBalances.pending, (state, { meta }) => {
				if (!meta.arg.mail) {
					state.loading = 'balances';
				} else {
					state.balances = state.balances.some(
						(balance) => balance.mail === meta.arg.mail
					)
						? state.balances.map((balance) =>
								balance.mail === meta.arg.mail
									? {
											...balance,
											loading: true,
										}
									: balance
							)
						: state.balances.concat({
								mail: meta.arg.mail,
								balance: 0,
								loading: true,
							});
				}
			})
			.addCase(getBalances.rejected, (state, { payload, meta }) => {
				state.balances = state.balances.map((balance) =>
					balance.mail === meta.arg.mail
						? {
								...balance,
								error: payload,
							}
						: balance
				);
				state.loading = undefined;
			});
		builder
			.addCase(insertResults.fulfilled, (state, { payload, meta }) => {
				state.results = state.results?.map((result) =>
					result.team === meta.arg.team
						? {
								...result,
								loading: false,
								error: false,
							}
						: result
				);
				state.points = [
					...state.points.filter(
						(points) =>
							!payload.some(
								(payload) =>
									payload.mail === points.mail &&
									payload.team === points.team &&
									payload.rank === points.rank
							)
					),
					...payload.map((points) => ({
						...points,
						acquisition: new Date(points.acquisition).toUTCString(),
						expiration: new Date(points.expiration).toUTCString(),
					})),
				];
				state.loading = undefined;
			})
			.addCase(insertResults.pending, (state, action) => {
				state.results = state.results?.map((result) =>
					result.team === action.meta.arg.team
						? {
								...result,
								loading: true,
								error: false,
							}
						: result
				);
				state.loading = 'points';
			})
			.addCase(insertResults.rejected, (state, { meta }) => {
				state.results = state.results?.map((result) =>
					result.team === meta.arg.team
						? {
								...result,
								loading: false,
								error: true,
							}
						: result
				);
				state.loading = undefined;
			});
		builder
			.addCase(getPoints.fulfilled, (state, { payload, meta }) => {
				state.results = state.results?.map((result) =>
					result.team === meta.arg.team
						? {
								...result,
								loading: false,
								error: false,
							}
						: result
				);
				state.points = [
					...state.points.filter(
						(points) =>
							!payload.some(
								(payload) =>
									payload.mail === points.mail &&
									payload.team === points.team &&
									payload.rank === points.rank
							)
					),
					...payload.map((points) => ({
						...points,
						acquisition: new Date(points.acquisition).toUTCString(),
						expiration: new Date(points.expiration).toUTCString(),
					})),
				];
				state.loading = undefined;
			})
			.addCase(getPoints.pending, (state, { meta }) => {
				state.results = state.results?.map((result) =>
					result.team === meta.arg.team
						? {
								...result,
								loading: true,
								error: false,
							}
						: result
				);
				state.loading = 'points';
			})
			.addCase(getPoints.rejected, (state, { meta }) => {
				state.results = state.results?.map((result) =>
					result.team === meta.arg.team
						? {
								...result,
								loading: false,
								error: true,
							}
						: result
				);
				state.loading = undefined;
			});
		builder
			.addCase(insertPoints.fulfilled, (state, { payload }) => {
				state.points = [
					...state.points.filter(
						(points) =>
							points.team !== payload.team || points.mail !== payload.mail
					),
					{
						...payload,
						acquisition: new Date(payload.acquisition).toUTCString(),
						expiration: new Date(payload.expiration).toUTCString(),
					},
				];
				state.editPoints = undefined;
				state.loading = undefined;
				state.send = false;
			})
			.addCase(insertPoints.pending, (state) => {
				state.loading = 'points';
				state.error = undefined;
			})
			.addCase(insertPoints.rejected, (state, { payload }) => {
				state.loading = undefined;
				state.error = payload;
			});
	},
});

const calcPositions = (balances: Balance[]) => {
	const sortedBalances = balances.sort((a, b) => b.balance - a.balance);

	const listPositions = sortedBalances.reduce(
		(acum: { [balance: number]: number }, curr, index) => ({
			...acum,
			...(curr.balance in acum ? {} : { [curr.balance]: index + 1 }),
		}),
		{}
	);

	return sortedBalances.map((balance) => ({
		...balance,
		position: listPositions[balance.balance],
	}));
};

const calcPoints = (
	setup: Setup,
	balances: {
		self: Balance[];
		opponent: Balance[];
	}
) => {
	switch (setup['format']) {
		case 'points':
			return setup.value;
		case 'percent':
			return Math.round(
				((setup['value'] / 100) *
					balances[setup['of']].reduce(
						(result, player) => result + player.balance,
						0
					)) /
					(balances[setup['of']].length === 0
						? 1
						: balances[setup['of']].length)
			);
		default:
			return 0;
	}
};

export const calcGroupsResults = (
	groups: Group[],
	matches: Match[],
	tournament: Tournament,
	event: Event
) => {
	let results: (Result & Closed)[] = [];

	groups.forEach((group) => {
		calcGroupResults(
			group,
			matches.filter((match) => match.group === group.id),
			tournament,
			event
		).forEach((newResult) => {
			const isBetter =
				!results.some((result) => result.team === newResult.team) ||
				newResult.points >
					(results.find((result) => result.team === newResult.team)?.points ??
						0);

			if (isBetter) {
				results = results
					.filter((result) => result.team !== newResult.team)
					.concat(newResult);
			}
		});
	});

	return results;
};

export const calcGroupResults = (
	group: Group,
	matches: Match[],
	tournament: Tournament,
	event: Event
) => {
	const results: (Result & Closed)[] = [];

	const fase = tournament.fases?.[group.fase];

	const format: 'reduct' | 'fixed' = !fase?.earnings?.length
		? 'reduct'
		: 'fixed';

	const hasThird = !!fase?.third;

	switch (group.draw) {
		case 'knockout':
			knockoutRounds(group, matches, hasThird)
				.reverse()
				.forEach((round, roundIndex) => {
					round.forEach((game, gameIndex) => {
						game.teams.forEach((team) => {
							const position =
								roundIndex === 0
									? Math.pow(2, roundIndex) +
										gameIndex * 2 +
										(team.id === game?.match?.winner ? 0 : 1)
									: Math.pow(2, roundIndex) + 1;

							const calcPow =
								roundIndex === 0
									? gameIndex * 2 + (team.id === game?.match?.winner ? 0 : 1)
									: roundIndex + 1;

							const calcReductPoints = Math.round(
								+(fase?.points ?? 0) *
									Math.pow(+(fase?.reduct ?? 0) / 100, calcPow)
							);

							const calcFixedPoints =
								fase?.earnings?.[position - 1] ??
								fase?.earnings?.[Math.pow(2, roundIndex) + 1] ??
								0;

							const points =
								format === 'fixed' ? calcFixedPoints : calcReductPoints;

							if (
								team?.id &&
								tournament.ranking &&
								(game.match?.winner || game.match?.walkover) &&
								(!hasThird || roundIndex !== 1)
							) {
								results.push({
									champ: event.id,
									tourn: tournament.id,
									rank: tournament.ranking,
									group: group.id,
									draw: group.draw,
									team: team.id,
									closed: group.closed,
									position,
									points,
									descr: `${event.name?.trim()} ${event.edition}${tournament.sign ? ` (${tournament.sign.trim()})` : ''}`,
								});
							}
						});
					});
				});

			return results;
		case 'group':
			orderByTeamStats(
				group,
				matches,
				fase?.criteria,
				tournament.sport
			).forEach((team, index) => {
				const position = index + 1;

				const points =
					format === 'fixed'
						? (fase?.earnings?.[position - 1] ?? fase?.earnings?.[0] ?? 0)
						: Math.round(
								+(fase?.points ?? 0) *
									Math.pow((fase?.reduct ?? 0) / 100, index)
							);

				if (team?.id && tournament.ranking) {
					results.push({
						champ: event.id,
						tourn: tournament.id,
						rank: tournament.ranking,
						group: group.id,
						draw: group.draw,
						team: team.id,
						closed: group.closed,
						position,
						points,
						descr: `${event.name?.trim()} ${event.edition}${tournament.sign ? ` (${tournament.sign.trim()})` : ''}`,
					});
				}
			});

			return results;
		default:
			return results;
	}
};

const calcChallResults = (
	challenge: Challenge,
	enrollments: Enrollment[],
	match: Match,
	ranking: Ranking,
	balances: Balance[]
) => {
	let results: (Result & Closed)[] = [];

	Object.keys(challenge.teams ?? {}).forEach((teamType) => {
		const oppType: keyof Chall =
			(teamType as keyof Chall) === 'challenger' ? 'challenged' : 'challenger';

		const self: Partial<Enrollment> | undefined =
			challenge.teams?.[teamType as keyof Chall];
		const opponent: Partial<Enrollment> | undefined =
			challenge.teams?.[oppType as keyof Chall];

		const resultSetup: ResultSetup | undefined =
			ranking.chall?.[teamType as keyof Chall];

		const resultType: keyof ResultSetup =
			match.walkover && match.winner !== self?.id
				? 'wo'
				: match.winner === self?.id
					? 'winner'
					: 'loser';

		const selfBalances = balances.filter((player) => {
			const enrolled = enrollments.find((enroll) => enroll.id === self?.id);
			return enrolled?.team?.some((partic) => partic.mail === player.mail);
		});

		const opponentBalances = balances.filter((player) => {
			const enrolled = enrollments.find((enroll) => enroll.id === opponent?.id);
			return enrolled?.team?.some((partic) => partic.mail === player.mail);
		});

		const winner = match.winner === self?.id;

		if (self?.id && resultSetup) {
			results = results.concat({
				chall: challenge.id,
				rank: challenge.rank,
				team: self.id,
				position:
					match.winner === challenge.teams?.[teamType as keyof Chall]?.id
						? 1
						: 2,
				points:
					(winner ? 1 : -1) *
					calcPoints(resultSetup[resultType], {
						self: selfBalances,
						opponent: opponentBalances,
					}),
				closed: challenge.closed,
				// acquisition,
				// expiration,
			});
		}
	});

	return results;
};

export const getRanking = createAsyncThunk<
	Ranking,
	string,
	{ rejectValue: SerializedError }
>('rankings/getRanking', async (rank, { rejectWithValue, dispatch }) => {
	try {
		await dispatch(authRefresh());

		const { data } = await api.ranking.get(rank);

		return data;
	} catch (error) {
		return rejectWithValue(
			(error as AxiosError).response?.data as SerializedError
		);
	}
});

export const getBalances = createAsyncThunk<
	Balance[],
	{
		rank: string;
		date: string;
		mail?: string;
	},
	{ rejectValue: SerializedError }
>(
	'rankings/getBalances',
	async ({ rank, mail, date }, { rejectWithValue, dispatch }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.rankings.balances.get(rank, mail, date);

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		}
	}
);

export const insertResults = createAsyncThunk<
	Points[],
	Result,
	{ rejectValue: SerializedError }
>('rankings/insertResults', async (result, { rejectWithValue, dispatch }) => {
	try {
		await dispatch(authRefresh());

		const { data } = await api.rankings.results.post(result);

		return data;
	} catch (error) {
		return rejectWithValue(
			(error as AxiosError).response?.data as SerializedError
		);
	}
});

export const getPoints = createAsyncThunk<
	Points[],
	{
		rank: string;
		mail?: string;
		team?: string;
		date?: string;
	},
	{ rejectValue: SerializedError }
>(
	'rankings/getPoints',
	async ({ rank, mail, team, date }, { rejectWithValue, dispatch }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.rankings.points.get(rank, {
				mail,
				team,
				date,
			});

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		}
	}
);

export const insertPoints = createAsyncThunk<
	Points,
	Pick<
		Points,
		| 'rank'
		| 'team'
		| 'mail'
		| 'name'
		| 'type'
		| 'descr'
		| 'points'
		| 'acquisition'
		| 'expiration'
	>,
	{ rejectValue: SerializedError }
>('rankings/insertPoints', async (points, { rejectWithValue, dispatch }) => {
	try {
		await dispatch(authRefresh());

		const { data } = await api.rankings.points.patch(points);

		return data;
	} catch (error) {
		return rejectWithValue(
			(error as AxiosError).response?.data as SerializedError
		);
	}
});

export const removePoints = createAsyncThunk<
	Points,
	Pick<Points, 'rank' | 'team' | 'mail' | 'active'>,
	{ rejectValue: SerializedError }
>('rankings/insertPoints', async (points, { rejectWithValue, dispatch }) => {
	try {
		await dispatch(authRefresh());

		const { data } = await api.rankings.points.patch(points);

		return data;
	} catch (error) {
		return rejectWithValue(
			(error as AxiosError).response?.data as SerializedError
		);
	}
});

export const {
	calcTournResults,
	calcRankResults,
	setResultsAcquisition,
	setResultExpiration,
	editPoints,
	changePoints,
	cancelPoints,
	setFilters,
	filterBalances,
	setRankings,
	setSend,
	resetFilters,
	resetResults,
	resetBalances,
	resetPoints,
	resetRanking,
} = rankingSlice.actions;

export default rankingSlice.reducer;
