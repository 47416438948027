import dayjs from 'dayjs';
import { SemanticICONS } from 'semantic-ui-react';

import { Age } from '../../store/leagues';

export const ageIcon = (rule: Age['rule']) =>
	`angle double ${rule}` as SemanticICONS;

export const ageText = (rule: Age['rule'], age: Age['age']) =>
	`${rule === 'up' ? 'Acima de' : 'Até'} ${age} anos`;

export const ageYears = (
	date?: string,
	format?: 'date (age)' | 'age' | 'date'
) => {
	if (!date) return '';

	const birth = dayjs(date);
	const age = dayjs().diff(birth, 'year');

	switch (format) {
		case 'date (age)':
			return `${birth.format('DD/MM/YYYY')} (${age} anos)`;
		case 'age':
			return `${age} anos`;
		default:
			return birth.format('DD/MM/YYYY');
	}
};

export const ageCalc = (
	birth: string | number | Date,
	date?: string | number | Date
) => {
	const birthDate = dayjs(birth);
	const currentDate = dayjs(date);

	const age = currentDate.diff(birthDate, 'year');

	return age;
};
