import { useRef } from 'react';

export const useInputFocus = () => {
	const inputRef = useRef<HTMLInputElement>(null);

	const setInputFocus = () => {
		if (inputRef.current) inputRef.current.focus();
	};

	return { inputRef, setInputFocus };
};
